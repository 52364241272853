/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-01-12",
    versionChannel: "nightly",
    buildDate: "2023-01-12T00:15:13.700Z",
    commitHash: "6c71f5b2a69895a1ca04a12c60afade3b8033b33",
};
